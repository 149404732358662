import _ from 'lodash';
import React, { useRef } from 'react';
import {
  StateDropdown, StatusDropdown,
  ProofingCompleteIcon, ClientApprovedIcon, AwaitingProofIcon,
  ProofReceivedIcon, PendingApprovalIcon, ChangeRequestedIcon,
  ShowPopup, ColumnSelectIcon, colors, PromostandardsIcon,ConnectedPlusIcon
} from '@commonsku/styles';

import DateInput from '../DateInput';

import CheckOrderStatusPopup from '../../components/CheckOrderStatusPopup';

import { parseMysqlDate, truncate, formatProductionMoney } from '../../utils';

const truncateLength = 25;

function customDateInput(date) {
  if(Array.isArray(date)) {
    return (
      <div style={{
        backgroundColor: '#F4F7FF',
        padding: '8px 16px 8px 0px',
        color: 'black',
        borderRadius: '100px',
        lineHeight: 1.25
      }}>
        {date[0]}
        <br />
        {date[1]}
      </div>
    );
  }

  return (
    <div style={{
      backgroundColor: '#F4F7FF',
      padding: '8px 8px',
      color: 'black',
      borderRadius: '100px'
    }}>
      {date}
    </div>
  );
}

export function ProofIcon({ proof_status_name }) {
  switch(proof_status_name) {
    case 'Proofing Complete':
      return <ProofingCompleteIcon  />;
    case 'No Proof Required':
      return null;
    case 'Proof Received':
      return <ProofReceivedIcon  />;
    case 'Pending Approval':
      return <PendingApprovalIcon  />;
    case 'Client Approved':
      return <ClientApprovedIcon  />;
    case 'Awaiting Proof':
      return <AwaitingProofIcon />;
    case 'Change Requested':
      return <ChangeRequestedIcon  />;
  }
}

function renderTooltip(value) {
  return (
    <span style={{
      width: '150px',
      backgroundColor: '#fff',
      color: 'black',
      textAlign: 'center',
      borderRadius: '6px',
      padding: '5px 10px',
      position: 'absolute',
      border: '1px solid #E2E6EF',
      boxShadow: '0px 1px 5px #2d394026',
      zIndex: 5
    }}>{value}</span>
  );
}

export function poIdCol(onClick) {
  return {
    Header: () => <span onClick={onClick} style={{ float: 'left' }}><ColumnSelectIcon size="large" /></span>,
    accessor: 'purchase_order_id',
    sticky: 'left',
    noDrag: true,
    disableSortBy: true,
    width: 50,
    isRowId: true
  };
}

export const supplierCol = {
  Header: () => {
    return (
      <span style={{ textAlign: "left", float: "left" }}>Supplier</span>
    );
  },
  accessor: 'supplier_name',
  sticky: 'left',
  noDrag: true,
  width: 180,
  hasTooltip: true,
  tooltipContent: (row) => {
    if(row.supplier_name && (row.supplier_name.length < truncateLength)) {
      return null;
    }

    return renderTooltip(row.supplier_name);
  },
  Cell: (row) => {
    const po = row.row.original;
    const wrapper = useRef(null);

    function setZIndex(z) {
      wrapper.current.parentNode.style.zIndex = z;
    }

    return (
      <div ref={wrapper} onMouseEnter={() => setZIndex(4)} onMouseLeave={() => setZIndex(3)} style={{ textAlign: 'left' }}>
        <span style={{ textAlign: 'left' }}>
          <a style={{ ...styles.link, marginTop: '5px' }} href={`vendor.php?id=${po.supplier_id}&division_id=${po.division_id}`} target="_blank" rel="noopener noreferrer">
            {truncate(row.value, truncateLength)}
          </a>
        </span>
      </div>
    );
  }
};

export function standingCol(tableStandings) {
  return {
    Header: 'Status',
    accessor: 'standing_order',
    width: 100,
    Cell: (row) => {
      const po = row.row.original;
      const wrapper = useRef(null);

      return (
        <div ref={wrapper}>
          <StatusDropdown
            items={tableStandings}
            value={_.find(tableStandings, { value: po.standing })}
            row={po}
            //disabled={true}
          />
        </div>
      );
    }
  };
}

export const poCol = (po_with_job_number) => {
  return {
    Header: 'PO',
    accessor: 'form_number',
    sticky: 'left',
    noDrag: true,
    width: po_with_job_number == 1 ? 130 : 80,
    Cell: (row) => {
      const po = row.row.original;

      return (
        <div style={{ textAlign: 'center' }}>
          <a href={`purchase_order.php?id=${po.purchase_order_id}`} target="_blank" rel="noopener noreferrer">
            {po_with_job_number == 1 ? `${po.job_number}-${po.form_number}` : row.value}
          </a>
        </div>
      );
    }
  };
};

export function stageCol(tableStatuses) {
  return {
    Header: 'Stage',
    accessor: 'stage',
    width: 150,
    Cell: (row) => {
      const po = row.row.original;

      return (
        <StateDropdown
          items={_.filter(tableStatuses, ({ order, content }) => {
            return order > 0 || content === po.status_name;
          })}
          value={_.find(tableStatuses, { content: po.status_name })}
          row={po}
          scrollContentHeight="300px"
          maxCircles={tableStatuses.length - 2}
        />
      );
    }
  };
}

export function proofStatusCol(onClick) {
  return {
    Header: 'Proof',
    accessor: 'proof_status_name',
    width: 100,
    hasTooltip: true,
    tooltipContent: (row) => {
      return renderTooltip(row.proof_status_name);
    },
    Cell: (row) => {
      const po = row.row.original;

      return (
        <div style={{ textAlign: "center" }}>
          <div onClick={() => onClick(po)} style={{ cursor: 'pointer' }}>
            <ProofIcon proof_status_name={row.value} />
          </div>
        </div>
      );
    }
  };
}

export const clientCol = {
  Header: 'Client',
  accessor: 'client_name',
  width: 180,
  hasTooltip: true,
  tooltipContent: (row) => {
    if(row.client_name && (row.client_name.length < truncateLength)) {
      return null;
    }

    return renderTooltip(row.client_name);
  },
  Cell: (row) => {
    const po = row.row.original;

    return (
      <div style={{ textAlign: 'center' }}>
        <a style={styles.link}
          href={`client.php?id=${po.client_id}`} target="_blank" rel="noopener noreferrer">
          {truncate(row.value, truncateLength)}
        </a>
      </div>
    );
  }
};

export const firmCol = {
  Header: 'Firm',
  accessor: 'po_firm',
  width: 70,
  Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>
};

export function shipDateCol(onChange) {
  return {
    Header: 'Proj Ship',
    accessor: 'date_shipdatecurrent',
    width: 100,
    Cell: (row) => {
      const po = row.row.original;
      const displayDate = po.date_shipdatecurrent && po.date_shipdatecurrent !== '0000-00-00 00:00:00'
        ? parseMysqlDate(po.date_shipdatecurrent, 'n/a', false, true)
          : 'n/a';

      return (
        <div style={{ textAlign: 'center', cursor: 'pointer' }}>
          <DateInput
            customInput={customDateInput(displayDate)}
            showMonthDropdown
            showYearDropdown
            placeholder="Projected Ship Date"
            value={po.date_shipdatecurrent}
            onChange={date => onChange(date, po)}
            portalId="root-portal"
            popperPlacement="auto"
            isClearable={true}
          />
        </div>
      );
    }
  };
}

export const projectNumberCol = {
  Header: 'Project #',
  accessor: 'job_number',
  Cell: (row) => {
    const po = row.row.original;

    return (
      <div style={{ textAlign: 'center' }}>
        <a href={`project.php?id=${po.job_id}`} target="_blank" rel="noopener noreferrer">
          {row.value}
        </a>
      </div>
    );
  }
};

export const projectNameCol = {
  Header: 'Project Name',
  accessor: 'job_name',
  hasTooltip: true,
  tooltipContent: (row) => {
    if(row.job_name && (row.job_name.length < truncateLength)) {
      return null;
    }

    return renderTooltip(row.job_name);
  },
  Cell: (row) => {
    const po = row.row.original;

    return (
      <div style={{ textAlign: 'center' }}>
        <a style={styles.link}
          href={`project.php?id=${po.job_id}`} target="_blank" rel="noopener noreferrer">
          {truncate(row.value, truncateLength)}
        </a>
      </div>
    );
  }
};

export const soCol = {
  Header: 'SO #',
  accessor: 'order_form_number',
  Cell: (row) => {
    const po = row.row.original;

    return (
      <div style={{ textAlign: 'center' }}>
        <a href={`sales_order.php?id=${po.order_id}`} target="_blank" rel="noopener noreferrer">
          {row.value}
        </a>
      </div>
    );
  }
};

export const poTotalCol = {
  Header: <span style={{ float: 'right' }}>Total</span>,
  accessor: 'total',
  width: 130,
  Cell: (row) => <div style={{ textAlign: "right" }}>{formatProductionMoney(row.value)}</div>
};

export const clientRepCol = {
  Header: 'Client Rep',
  accessor: 'client_rep_full_name',
  Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>
};

export const orderRepCol = {
  Header: 'Order Rep',
  accessor: 'order_rep',
  Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>
};

export const productionRepCol = {
  Header: 'Prod. Rep',
  accessor: 'purchase_order_rep_full_name',
  Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>
};

export const inHandsDateCol = {
  Header: 'IH Date',
  accessor: 'po_or_so_in_hands_date',
  width: 100,
  Cell: (row) => {
    const displayDate = parseMysqlDate(row.value) === '0000-00-00' ? 'n/a' : parseMysqlDate(row.value, 'n/a', false, true);

    if(Array.isArray(displayDate)) {
      return (
        <div style={{ textAlign: "center", lineHeight: 1.25 }}>
          {displayDate[0]}
          <br/>
          {displayDate[1]}
        </div>
      );
    }else{
      return (
        <div style={{ textAlign: "center" }}>
          {displayDate}
        </div>
      );
    }
  }
};

export function nextActionTypeCol(tableNextActionTypes, next_action_types) {
  return {
    Header: 'Next Action',
    accessor: 'next_action_type',
    hasTooltip: true,
    tooltipContent: (row) => {
      const next_action_type_id = !row.next_action_type_id ? 'none' : row.next_action_type_id;
      const next_action_type = _.find(next_action_types, { value: next_action_type_id });

      if(next_action_type && next_action_type.label) {
        //using 20 here instead of truncateLength
        //that is the length truncated in next_action_types
        if(next_action_type.label.length < 20) {
          return null;
        }
      }

      return renderTooltip(next_action_type ? next_action_type.label : 'none');
    },
    Cell: (row) => {
      const po = row.row.original;
      let next_action_type_id = !po.next_action_type_id ? 'none' : po.next_action_type_id;
      const next_action_type = _.find(next_action_types, { value: next_action_type_id });
      if(!next_action_type) {
        next_action_type_id = 'none';
      }

      return (
        <>
          <StateDropdown
            items={tableNextActionTypes}
            value={_.find(tableNextActionTypes, { value: next_action_type_id })}
            row={po}
            showCircles={false}
            scrollContentHeight="300px"
          />
        </>
      );
    }
  };
}

export function nextActionDateCol(onChange) {
  return {
    Header: 'NA Date',
    accessor: 'date_nextaction',
    width: 100,
    Cell: (row) => {
      const po = row.row.original;
      const displayDate = po.date_nextaction && po.date_nextaction !== '0000-00-00 00:00:00'
        ? parseMysqlDate(po.date_nextaction, 'n/a', false, true)
          : 'n/a';

      return (
        <div style={{ textAlign: 'center', cursor: 'pointer' }}>
          <DateInput
            customInput={customDateInput(displayDate)}
            showMonthDropdown
            showYearDropdown
            placeholder="Next Action Date"
            value={po.date_nextaction}
            onChange={date => onChange(date, po)}
            portalId="root-portal"
            popperPlacement="auto"
          />
        </div>
      );
    }
  };
}

export function psCol(onChange) {
  return {
    Header: 'PS',
    accessor: 'promostandards',
    disableSortBy: true,
    width: 60,
    Cell: (row) => {
      const po = row.row.original;

      if((po.order_status_check_enabled == '1' || po.shipment_check_enabled == '1') && po.has_credentials == '1') {
        return (
          <ShowPopup popup={CheckOrderStatusPopup}
            purchase_order={po}
            purchase_order_id={po.purchase_order_id}
            division_id={po.division_id}
            form_number={po.form_number}
            date_shipped={po.date_shipped}
            onUpdate={onChange}
            resku={false}
            render={({ onClick }) => {
              return (
                <div style={{ textAlign: "center", cursor: 'pointer' }} onClick={onClick}>
                  {/* <img src="/images/promostandard-icon.png" style={{ width: '25px' }} /> */}
                  <ConnectedPlusIcon />
                </div>
              );
            }}
          />
        );
      }

      return <div style={{ textAlign: "center" }}>n/a</div>;
    }
  };
}

const styles = {
  link: {
    lineHeight: '1rem',
    display: 'inline-block'
  }
};

import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Button, ShowPopup, Box, HeadlessTable, Loading as StyledLoading } from '@commonsku/styles';

import Loading from './Loading';
import {
  poIdCol, supplierCol, standingCol, poCol, stageCol, proofStatusCol, clientCol, firmCol, shipDateCol,
  projectNumberCol, projectNameCol, soCol, poTotalCol, orderRepCol, clientRepCol, productionRepCol,
  inHandsDateCol, nextActionTypeCol, nextActionDateCol, psCol
} from './production-report/Columns';
import SaveViewPopup from './production-report/SaveViewPopup';
import ColumnSelector from './production-report/ColumnSelector';
import StatePanel from './production-report/StatePanel';
import Filter from './production-report/Filter';
import Tabs from './production-report/Tabs';

import { deleteReportView, clearPurchaseOrders } from '../actions';
import { updatePurchaseOrder, fetchPOForProduction } from '../actions/purchase_order';
import { createValidateConfirmationPopup } from '../actions/popup';

import { getUserOptions, getStatusOptions, getSupplierAccountOptions, getClientOptions, getShipMethodOptions, getNextActionTypeOptions } from '../selectors/dropdowns';
import { getCommonskuStyleDropdownOptions, getIdentityUtils, openWindowWithPost, oauth, parseDate } from '../utils';
import { window, document } from '../global';
import Status from '../constants/Status';

const result_text = {
  'new': 'Please click on Get Report to fetch the most recent report.',
  'empty': 'No purchase orders found.'
};

const sortColumnsConfig = (keys, columnsConfig) => {
  return _.mapValues(columnsConfig, (config, key) => {
    const column_order = keys.indexOf(key);
    return {...config, hidden: column_order === -1, column_order };
  });
};

export default function ProductionReportView({
  sidePanelRow,
  setSidePanelRow,
  setSelectedSidePanelTab,

  showPopupTable=false,
  storeLoadingKeys=[],
  posFilters={},
}) {
  const dispatch = useDispatch();

  const identity = useSelector(state => state.identity || {});
  let purchase_orders = useSelector(state => {
    return Object.values(state.entities.purchase_orders);
  });

  function lockTable() {
    if(pageIndexDivRef.current) {
      const pageIndex = pageIndexDivRef.current.innerText;
      if(pageIndex !== '0') {
        setDefaultPageIndex(parseInt(pageIndex));
      }
    }
    if(sortDirectionDivRef.current) {
      const sortData = JSON.parse(sortDirectionDivRef.current.innerText);
      if(Object.keys(sortData).length) {
        const default_sort = {
          id: sortData.accessor,
          desc: sortData.direction === 'DESC' ? true : false
        };
        setDefaultSort(default_sort);
      }
    }
    if(scrollOffsetDivRef.current) {
      setDefaultScrollOffset(parseInt(scrollOffsetDivRef.current.innerText) || 0);
    }
    if(horizontalOffsetDivRef.current) {
      setDefaultHorizontalOffset(parseInt(horizontalOffsetDivRef.current.innerText) || 0);
    }
  }
  const report_views = _.orderBy(
    Object.values(useSelector(state => state.entities.report_views, _.isEqual)),
    ['is_default', 'display_order'], ['desc', 'asc']
  );
  const default_report_views = _.filter(report_views, ({ is_default }) => is_default == 1);

  let users = useSelector(state => getCommonskuStyleDropdownOptions(getUserOptions(state)), _.isEqual);
  const { hasCapabilities } = getIdentityUtils(identity);
  if(hasCapabilities(['LIMITED-ACCESS'])) {
    users = users.filter(u => u.value === identity.user_id);
  }
  users = [{ value: '', label: 'All' }].concat(users);

  let statuses = useSelector(state => getCommonskuStyleDropdownOptions(getStatusOptions(state, { order_type: 'PURCHASE ORDER' })), _.isEqual);
  const tableStatuses = statuses.map((s, i) => {
    // For the dots on the dropdown
    // Problem and Follow up is getting deprecated
    // The number of dots should be 0 	╮(︶︿︶)╭
    const status_order = s.label === 'Problem' || s.label === 'Follow Up' ? 0 : i - 1;

    return {
      value: s.value,
      content: s.label,
      order: status_order,
      onClick: (item, row) => {
        dispatch(updatePurchaseOrder(row.purchase_order_id, 'status_id', row.status_id, item.value));
        lockTable();
      }
    };
  });

  const today = new Date();
  purchase_orders = _.map(
    _.filter(purchase_orders, v => {
      const keys = Object.keys(posFilters);
      if (keys.length === 0) {
        return true;
      }

      const result = keys.filter(k => {
        const value = posFilters[k];
        if (k === 'production_rep_ids') {
          const hasProdRepIds = value && value.length && value !== 'ALL';
          return value === 'ALL' || (hasProdRepIds && value.includes(v.production_rep_id));
        } else if (k === 'next_action_date_overdue' && value) {
          return v.next_action_type_id && v.date_nextaction
            && new Date(parseDate(v.date_nextaction)) < today
            && ![
              Status.PURCHASE_ORDER_READY_FOR_BILLING,
              Status.PURCHASE_ORDER_CLOSED,
              Status.PURCHASE_ORDER_BILLED,
            ].includes(v.status_id);
        } else if (k === 'inhands_date_overdue') {
          let dt = v.date_inhandsdate;
          if (v.shipdate_option !== 'IN-HANDS-DATE') {
            dt = v.in_hands_date;
          }
          return dt && new Date(parseDate(dt)) < today
            && ![
              Status.PURCHASE_ORDER_READY_FOR_BILLING,
              Status.PURCHASE_ORDER_CLOSED,
              Status.PURCHASE_ORDER_BILLED,
            ].includes(v.status_id);
        } else if (k === 'proj_ship_date_overdue') {
          return v.date_shipdatecurrent
            && new Date(parseDate(v.date_shipdatecurrent)) < today
            && ![
              Status.PURCHASE_ORDER_SHIPPED,
              Status.PURCHASE_ORDER_READY_FOR_BILLING,
              Status.PURCHASE_ORDER_CLOSED,
              Status.PURCHASE_ORDER_BILLED,
            ].includes(v.status_id);
        }
        return v[k] === value;
      });
      return result.length >= keys.length;
    }),
    (po) => {
      const order = _.find(tableStatuses, {content: po.status_name}).order;
      return {
        ...po,
        stage: order === 0 && po.status_name === 'Problem' ? -1 : order,
        standing_order: ['PROBLEM', 'FOLLOWUP', 'OK'].indexOf(po.standing),
      };
    }
  );

  const filterStatuses = [{ value: 'open', label: 'Open' }, { value: 'unshipped', label: 'In Production'}].concat(statuses.filter(s => s.value !== Status.PURCHASE_ORDER_PROBLEM && s.value !== Status.PURCHASE_ORDER_FOLLOW_UP));

  let suppliers = useSelector(state => getCommonskuStyleDropdownOptions(getSupplierAccountOptions(state)), _.isEqual);
  suppliers = [{ value: '', label: 'All' }].concat(suppliers);
  let clients = useSelector(state => getCommonskuStyleDropdownOptions(getClientOptions(state)), _.isEqual);
  clients = [{ value: '', label: 'All' }].concat(clients);
  let ship_methods = useSelector(state => getCommonskuStyleDropdownOptions(getShipMethodOptions(state, { parent_id: state.identity.company_id })), _.isEqual);
  ship_methods = [{ value: '', label: 'All' }].concat(ship_methods);
  let proof_statuses = useSelector(state => getCommonskuStyleDropdownOptions(getStatusOptions(state, { order_type: 'PROOF' })), _.isEqual);
  proof_statuses = [{ value: '', label: 'All' }].concat(proof_statuses);

  let next_action_types = useSelector(state => getCommonskuStyleDropdownOptions(getNextActionTypeOptions(state)), _.isEqual);
  next_action_types = [{ value: 'none', label: 'None Set' }].concat(next_action_types);
  const tableNextActionTypes = next_action_types.map((s, i) => {
    return {
      value: s.value,
      content: s.label,
      onClick: (item, row) => {
        dispatch(updatePurchaseOrder(row.purchase_order_id, 'next_action_type_id', row.next_action_type_id, item.value));
        lockTable();
      }
    };
  });
  next_action_types = [{ value: '', label: 'All' }].concat(next_action_types);

  // Columns
  const tableStandings = [
    {
      value: 'OK',
      content: 'OK',
      color: "#00D374",
      onClick: (item, row) => {
        dispatch(updatePurchaseOrder(row.purchase_order_id, 'standing', row.standing, item.value));
        lockTable();
      }
    },
    {
      value: 'PROBLEM',
      content: 'Problem',
      color: "#FF2674",
      onClick: (item, row) => {
        dispatch(updatePurchaseOrder(row.purchase_order_id, 'standing', row.standing, item.value));
        lockTable();
      }
    },
    {
      value: 'FOLLOWUP',
      content: 'Follow Up',
      color: "#FFAE00",
      onClick: (item, row) => {
        dispatch(updatePurchaseOrder(row.purchase_order_id, 'standing', row.standing, item.value));
        lockTable();
      }
    }
  ];
  const shipDateColView = shipDateCol(function(date, row) {
    dispatch(updatePurchaseOrder(row.purchase_order_id, 'date_shipdatecurrent', row.date_shipdatecurrent, date));
    lockTable();
  });
  const nextActionDateColView = nextActionDateCol(function(date, row) {
    dispatch(updatePurchaseOrder(row.purchase_order_id, 'date_nextaction', row.date_nextaction, date));
    lockTable();
  });
  const psColView = psCol((purchase_order_id, field, previous_value) => value => {
    dispatch(updatePurchaseOrder(purchase_order_id, field, previous_value, value));
    lockTable();
  });
  function onClickProofStatus(row) {
    setSelectedSidePanelTab('proof');
    onSelectRow(null, row);
  }

  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const defaultColumnsConfig = update(
    _.keyBy([
      poIdCol(() => setShowColumnSelector(true)),
      supplierCol,
      poCol(identity.po_with_job_number),
      standingCol(tableStandings),
      stageCol(tableStatuses),
      proofStatusCol(onClickProofStatus),
      clientCol, projectNameCol, projectNumberCol, soCol, poTotalCol,
      clientRepCol, orderRepCol, productionRepCol,
      nextActionTypeCol(tableNextActionTypes, next_action_types),
      nextActionDateColView, shipDateColView, inHandsDateCol, firmCol, psColView,
    ], 'accessor'),
    {
      proof_status_name: {selector_name: {$set: 'Proof Status'}},
      order_form_number: {
        selector_name: {$set: 'Sales Order #'},
        hidden: {$set: true}
      },
      total: {
        selector_name: {$set: 'PO Total'},
        hidden: {$set: true}
      },
      client_rep_full_name: {hidden: {$set: true}},
      order_rep: {hidden: {$set: true}},
      purchase_order_rep_full_name: {
        selector_name: {$set: 'Production Rep'},
        hidden: {$set: true}
      },
      next_action_type: {selector_name: {$set: 'Next Action Type'}},
      date_nextaction: {selector_name: {$set: 'Next Action Date'}},
      date_shipdatecurrent: {hidden: {$set: true}},
      po_or_so_in_hands_date: {selector_name: {$set: 'In Hands Date'}},
      po_firm: {hidden: {$set: true}},
      promostandards: {selector_name: {$set: 'Promostandards'}},
    }
  );
  const [columnsConfig, setColumnsConfig] = useState(sortColumnsConfig(
    _.keys(_.pickBy(defaultColumnsConfig, ({ hidden }) => !hidden)),
    defaultColumnsConfig
  ));

  const defaultFilters = {
    projectNumber: '',
    poNumber: '',
    soNumber: '',
    orderRepId: null,
    clientRepId: null,
    productionRepId: null,
    supplierId: null,
    clientId: null,
    shipMethodId: null,
    proofStatusId: null,
    standing: null,
    statusId: '',
    firm: null,
    nextActionTypeId: null,
    dateType: null,
    endDate: null,
    startDate: null,
    dateCategory: null,
  };
  const [filters, setFilters] = useState({
    ...defaultFilters,
    statusId: 'unshipped',
    dateCategory: '7',
  });

  // other states
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [search, setSearch] = useState(true);
  const [defaultPageIndex, setDefaultPageIndex] = useState(0);
  const [defaultSort, setDefaultSort] = useState(null);
  const [resultText, setResultText] = useState(result_text['new']);
  const [defaultScrollOffset, setDefaultScrollOffset] = useState(0);
  const [defaultHorizontalOffset, setDefaultHorizontalOffset] = useState(0);
  const [listHeight, setListHeight] = useState(0);
  const selectedReportView = _.get(report_views, selectedTab, {});
  const isDefault = selectedReportView.is_default == 1;
  const isLookup = isDefault && selectedReportView.name === 'Lookup';

  function calculateListHeight() {
    const tabBarHeight = showPopupTable ? 0 : document.getElementById('production-tabs') ? document.getElementById('production-tabs').clientHeight : 60;
    const panelHeight = showPopupTable ? 260 : document.getElementById('production-panel') ? document.getElementById('production-panel').clientHeight : 340;
    const list_height = window.innerHeight - (tabBarHeight + panelHeight) - 75;
    setListHeight(list_height);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', calculateListHeight);
    return () => window.removeEventListener('resize', calculateListHeight);
  }, []);

  useEffect(() => {
    if (!loading) {
      calculateListHeight();
    }
  }, [search, selectedTab, loading]);

  useEffect(() => {
    if(!selectedReportView.report_view_id) {
      return;
    }

    unlockTable();
    setResultText(result_text['new']);
    if (!showPopupTable) {
      dispatch(clearPurchaseOrders);
    }
    const { view, filter } = selectedReportView;
    const view_cols = JSON.parse(view);
    const accessors = _.map(view_cols, ({ accessor }) => {
      // rename standing and status_name for order column
      return _.get({standing: 'standing_order', status_name: 'stage' }, accessor, accessor);
    });
    setColumnsConfig(sortColumnsConfig(accessors, columnsConfig));
    const sortColumn = _.find(view_cols, ({ sortDirection }) => sortDirection);
    if (sortColumn) {
      setDefaultSort({ id: sortColumn.accessor, desc: sortColumn.sortDirection === 'DESC' });
    }
    const newFilters = _.reduce(JSON.parse(filter), (prev, value, key) => {
      return update(prev, {[key]: {$set: value}});
    }, defaultFilters);
    if (isLookup) {
      newFilters.statusId = 'all';
      newFilters.productionRepId = '';
    }
    setFilters(newFilters);
    if (isDefault) {
      setSearch(true);
    } else {
      onSearch(newFilters);
    }
  }, [selectedTab, showPopupTable]);

  function onResetFilter() {
    setFilters({ ...defaultFilters, statusId: isLookup ? 'all' : 'unshipped'});
    if (!showPopupTable) {
      dispatch(clearPurchaseOrders);
    }
  }

  function getSearchParams(newFilters) {
    let params = _.mapKeys(newFilters || filters, (value, key) => {
      return _.get({
        orderRepId: 'sales_rep_id',
        clientRepId: 'client_rep_id',
        productionRepId: 'production_rep_id',
        supplierId: 'supplier_id',
        clientId: 'client_id',
        statusId: 'status_id',
        projectNumber: 'job_number',
        poNumber: 'form_number',
        soNumber: 'order_form_number',
        shipMethodId: 'ship_method_id',
        proofStatusId: 'proof_status_id',
        nextActionTypeId: 'next_action_type_id',
        dateType: 'date_filter',
        startDate: 'start_stamp',
        endDate: 'end_stamp',
      }, key, key);
    });
    params = _.mapValues(params, (value, key) => {
      return _.get({
        status_id: value && value !== 'open',
        firm: (value === '1' || value === '0'),
        start_stamp: params['date_filter'],
        end_stamp: params['date_filter'],
      }, key, value) ? value || null : null;
    });
    if (params['date_filter'] && params['dateCategory'] !== 'custom') {
      if (params['dateCategory'] === 'overdue') {
        params['start_stamp'] = '';
        params['end_stamp'] = moment().format('YYYY-MM-DD');
      } else {
        params['start_stamp'] = moment().format('YYYY-MM-DD');
        params['end_stamp'] = moment().add(params['dateCategory'], 'days').format('YYYY-MM-DD');
      }
    }
    return _.pickBy(params, (value, key) => {
      return key != 'dateCategory' && value !== null;
    });
  }

  function unlockTable() {
    setDefaultPageIndex(0);
    setDefaultSort(null);
    setDefaultScrollOffset(0);
    setDefaultHorizontalOffset(0);
  }

  async function onSearch(newFilters) {
    const searchFilters = newFilters || filters;
    const { poNumber, soNumber, projectNumber } = searchFilters;
    if (isLookup && (!poNumber && !soNumber && !projectNumber)) {
      return;
    }
    unlockTable();
    setLoading(true);

    const params = getSearchParams(searchFilters);
    const pos = await dispatch(fetchPOForProduction(params));
    setResultText(!pos.length ? result_text['empty'] : null);
    setLoading(false);
    setSearch(isLookup);
  }

  const scrollOffsetDivRef = useRef(null);
  const horizontalOffsetDivRef = useRef(null);
  const sortDirectionDivRef = useRef(null);
  const currentColumnsDivRef = useRef(null);
  const pageIndexDivRef= useRef(null);
  const filterButtonStyle = { marginRight: '10px', marginBottom: '10px', padding: '5px 10px' };

  function onChangeSortOrColumns(sortDirection, currentColumns) {
    let view = [];
    _.each(currentColumns, function(column) {
      let col = {
        accessor: column,
        sortDirection: null
      };
      if(!_.isEmpty(sortDirection) && column === sortDirection.accessor) {
        col.sortDirection = sortDirection.direction;
      }
      view.push(col);
    });
    const json_view = JSON.stringify(view);
    //we do not want to dispatch action here to trigger store change
    oauth('PUT', `report-view/${selectedReportView.report_view_id}`, { view: json_view }).then(() => {
      setDefaultSort(_.isEmpty(sortDirection) ? null : {
        id: sortDirection.accessor, desc: sortDirection.direction === 'DESC',
      });
      setColumnsConfig(sortColumnsConfig(currentColumns, columnsConfig));
    });
  }

  function onSelectRow(purchase_order_id=false, po=false) {
    lockTable();
    setSidePanelRow(
      purchase_order_id ? _.find(purchase_orders, { purchase_order_id }) :
      po ? po : null
    );
  }

  function onDeleteReportView(tab) {
    dispatch(deleteReportView(tab)).then(() => {
      setSelectedTab(default_report_views[0].report_view_id);
    });
  }

  function onExport() {
    const params = getSearchParams();
    const obj = {
      'report_type': 'production',
      'csku_report': false,
      'export_all': true,
      ...params
    };

    openWindowWithPost("/export_report.php", obj);
  }

  function voidFunction() {
    // void function, do nothing
  }

  function renderTable() {
    return (
      <HeadlessTable
        columns={_.orderBy(_.filter(columnsConfig, ({ hidden }) => !hidden), ['column_order'])}
        data={purchase_orders}
        rowIdField="purchase_order_id"
        defaultSort={defaultSort}
        defaultPageIndex={defaultPageIndex}
        defaultScrollOffset={defaultScrollOffset}
        defaultHorizontalOffset={defaultHorizontalOffset}
        defaultPageSize={25}
        scrollOffsetDivRef={scrollOffsetDivRef}
        horizontalOffsetDivRef={horizontalOffsetDivRef}
        pageIndexDivRef={pageIndexDivRef}
        selectedRowId={sidePanelRow ? sidePanelRow.purchase_order_id : null}
        onChangeSelected={onSelectRow}
        sortDirectionDivRef={sortDirectionDivRef}
        currentColumnsDivRef={currentColumnsDivRef}
        onChangeSortOrColumns={showPopupTable ? voidFunction : onChangeSortOrColumns}
        minHeight={listHeight}
        pagination={false}
      />
    );
  }

  const showPopupLoading = useSelector(s => _.get(s, storeLoadingKeys, false));
  if (showPopupTable) {
    if (storeLoadingKeys.length && showPopupLoading) {
      return (
        <div style={{textAlign: 'center'}}>
          <StyledLoading />
        </div>
      );
    }
    return renderTable();
  }

  return (
    <div className="main-content" style={{ paddingRight: '12px' }}>
      <Tabs report_views={report_views} loading={loading} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
      {loading ?
        <Loading style={{ position: 'absolute', top: '53%', left: '50%' }} />
      :
        <>
          <Box id="production-panel" padded style={{ backgroundColor: '#f4f7ff', paddingBottom: 0, paddingTop: '0.5rem', marginTop: 0 }}>
            {search ?
              <>
                <Row style={{ flexDirection: 'row-reverse', padding: '0 13px' }}>
                  <a onClick={() => setSearch(false)} style={{ cursor: 'pointer' }}>&#8212;</a>
                </Row>
                <Filter
                  {...{
                    users, suppliers, clients, statuses: filterStatuses, ship_methods,
                    proof_statuses, next_action_types,
                  }}
                  filters={filters}
                  onChange={(key, value) => setFilters(update(filters, {[key]: {$set: value}}))}
                  onSubmit={(newFilters) => onSearch(newFilters)}
                  type={isDefault ? selectedReportView.name : ''}
                />
                <Row style={{ justifyContent: 'flex-end', marginTop: '10px' }}>
                  <Button cta onClick={() => onSearch()} style={filterButtonStyle}>Get Report</Button>
                  {hasCapabilities('EXPORT-REPORT') && <Button onClick={() => onExport()} style={filterButtonStyle}>Export</Button>}
                  {!isDefault && <Button
                    onClick={() => {
                      dispatch(createValidateConfirmationPopup(onDeleteReportView, [selectedReportView.report_view_id]));
                    }}
                    style={filterButtonStyle}
                  >Delete</Button>}
                  <Button onClick={() => onResetFilter()} style={filterButtonStyle}>Reset</Button>
                  {!isLookup && <ShowPopup popup={SaveViewPopup}
                    filter={filters}
                    sortDirectionDivRef={sortDirectionDivRef}
                    currentColumnsDivRef={currentColumnsDivRef}
                    report_view={selectedReportView}
                    onSave={(report_view_id) => {
                      setSelectedTab(_.findIndex(report_views, { report_view_id }));
                    }}
                    setLoading={setLoading}
                    render={({ onClick }) => {
                      return <Button onClick={onClick} style={filterButtonStyle}>Save</Button>;
                    }}
                  />}
                </Row>
              </>
            :
              <>
                <Row style={{ justifyContent: 'space-between' }}>
                  <StatePanel
                    users={users}
                    suppliers={suppliers}
                    clients={clients}
                    statuses={statuses}
                    ship_methods={ship_methods}
                    proof_statuses={proof_statuses}
                    next_action_types={next_action_types}
                    {...filters}
                  />
                  <div>
                    {isDefault ?
                      <Button cta onClick={() => setSearch(true)} style={filterButtonStyle}>New Search</Button>
                    :
                      <Button cta onClick={() => onSearch()} style={filterButtonStyle}>Get Report</Button>
                    }
                    {hasCapabilities('EXPORT-REPORT') && <Button onClick={() => onExport()} style={filterButtonStyle}>Export</Button>}
                    {isDefault ?
                      <ShowPopup popup={SaveViewPopup}
                        filter={filters}
                        sortDirectionDivRef={sortDirectionDivRef}
                        currentColumnsDivRef={currentColumnsDivRef}
                        report_view={selectedReportView}
                        onSave={(report_view_id) => {
                          setSelectedTab(_.findIndex(report_views, { report_view_id }));
                        }}
                        setLoading={setLoading}
                        render={({ onClick }) => {
                          return <Button onClick={onClick} style={filterButtonStyle}>Save</Button>;
                        }}
                      />
                    :
                      <Button onClick={() => setSearch(true)} style={filterButtonStyle}>Edit</Button>
                    }
                  </div>
                </Row>
              </>
            }
          </Box>
          {showColumnSelector && <ColumnSelector
            columns={_.mapValues(
              _.pickBy(columnsConfig, ({ noDrag }) => !noDrag),
              ({ Header, hidden, selector_name }) => {
                return { label: selector_name || Header, value: !hidden };
              }
            )}
            onChange={(key, visiable) => {
              const newConfig = update(columnsConfig, {
                [key]: {
                  hidden: {$set: !visiable},
                  column_order: {$set: _.filter(columnsConfig, ({ hidden }) => !hidden)}
                }
              });
              setColumnsConfig(newConfig);
              onChangeSortOrColumns(
                defaultSort && { accessor: defaultSort.id, sortDirection: defaultSort.desc },
                _.map(_.filter(newConfig, ({ hidden }) => !hidden), 'accessor')
              );
            }}
            mouseLeaveCallback={() => setShowColumnSelector(false)}
          />}
          {resultText ? <p style={{ position: 'absolute', marginTop: '60px' }}>{resultText}</p> : null}
          {renderTable()}
        </>
      }
    </div>
  );
}
